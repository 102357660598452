/*
|===============================================================
|	Notes
| + Add .section to :hover class for link which has sub children
|===============================================================
*/

* {
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
}


html{	
	width: 100%;
	height: 100%;
	//overflow:hidden;
}
body{
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

/*
|===============================================================
|	Scroll Bar
|===============================================================
*/
::-webkit-scrollbar{
	width:10px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color:#eee;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


fieldset{
	border: none;
}

.searchBox {
	width:40px;
	height:40px;
	position:fixed;
	background-color: $LinkColor;
	top:0px;
	right:30px;
	background-image:url(../images/search.svg);
	background-size:50% 50%;
	background-position:center 10px;
	background-repeat: no-repeat;
	z-index:5799;
}

#SearchForm_SearchForm {
	width:100%;
	max-width:275px;
	position:fixed;
	left:50%;
	top:50%;
	@include transform(translateX(-50%) translateY(-50%));
	display:none;
	z-index:9999;
	#SearchForm_SearchForm_Search {
		border:1px solid #FFFFFF;
		background-color:#FFFFFF;
		color:$LinkColor;

		    font-style: italic;
     position: absolute; 
    padding: 10px;
    z-index: 8888;
    height: 35px;
    left:0;

    border: 0px;
    width: 200px;
	}
	#SearchForm_SearchForm_action_results{
	    color: #ddd;
	    @include transition($t1);
		@include appearance(none);
	    outline: 0 !important;
	    position: absolute;
	    z-index: 9980;
	    font-family: WebSymbols;
	    text-decoration: none !important;
	    display: inline-block;
	    text-align: center;
	    cursor: pointer;
	    border: none !important;
	    margin-left: 20px;
	    font-size: 2em;
	    background-color: transparent;
	    right:0;
	    width:37px;
	    height:37px;
	    background-image:url(../images/searchIcon.svg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    &:hover{
	    	color: #fff;
	    }
	    i {
	    	color:#FFFFFF;
	    }
	}
}

#blackout, .blackout, .blackoutTranslate {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 5800;
    background-color: rgba(234, 75, 61, 0.85);
}
.noticesBlackout {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(42, 49, 115, 0.85);
}

/*
|===============================================================
|	Project Chart
|===============================================================
*/

table {
	margin: 0 0 2em;
	width: 100%;
	thead {
		tr {
			td{
				text-align: center;
			    padding: 5px;
			    font-size: 1.2em;
			    background-color: $themeColor;
			    padding: 15px 0;
			    color:#FFFFFF;
			    text-transform:uppercase;
			    a {
			    	color:#FFFFFF;
			    }

			}
			
		}
	}
	tbody {
		tr {
			td {
				padding: 0;
				border: 1px solid $themeColor;
			    text-align: center;
			    vertical-align: top;
			    .notesToggle {
			    	cursor:pointer;
			    }
			    .notes {
			    	display:none;
			    	max-width:400px;
			    	margin: 0 auto;
    text-align: justify;
			    }
			    
			}
			&:nth-child(even){
				background-color:$lightGrey;
			}
		}
	}
}

.ApprovalState0, .ApprovalState1 {
	/*display:block;*/
	color:#FFF;
	padding:5px;
	margin:1px 0;

	
}
.ApprovalState0 {
	background-color:#AB1A25;
}

.ApprovalState1 {
	background-color:#407A32;
}

/*
|===============================================================
|	Loading Bar
|===============================================================
*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

/*** Page Loading ***/
.Loading{
	@include transition(opacity 0.35s);
	opacity: 1;
	@include stretchCover(#bedff7, fixed, 9000);
}
.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

#home1{
	padding: 100px 0px
}

/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px;
}

/*
|===============================================================
|	Homepage News Widget
|===============================================================
*/


.homepageNewsSliderWrapper {
	background-color: #FFFFFF;
	padding:60px 30px;
	position:relative;
	text-align:center;
	background-image:url(../images/water.jpg);
	background-attachment: fixed;
	.topControls {
		text-align:left;
		width:100%;
		max-width:900px;
		margin:0 auto;
		position:relative;
		h1 {
			text-align: left;
			color:#FFFFFF;
			display: inline-block;
			padding-bottom:5px;
			margin-bottom:30px;
			font-size:50px;
			text-transform: uppercase;
			font-weight: 100;
		}
		.viewMoreBtn {
			padding:10px 30px;
			border:1px solid #FFFFFF;
			color:#FFFFFF;
			display:inline-block;
			font-weight:100;
			@include transition($t1);
			position: absolute;
			top:10px;
			right:126px;
			&:hover {
				background-color:#FFFFFF;
				color:$LinkColor;
			}
		}
	}
	
	.outer {
		max-width:900px;
		margin:0px auto 30px auto;
		.homepageNewsSlider {
			width:100%;
			/**/
			margin:0 auto;
			.slide {
				z-index:1;
				width:300px;
				.inner {
					/*margin:20px;*/
					background-color:#FFFFFF;
					text-align: left;
					
					.articleDate {
						background-color:$LinkColor;
						color:#FFFFFF;
						margin:20px 0;
						font-size:15px;
						padding:8px;
					}
					.title {
						color:$LinkColor;
						font-size:24px;
						height:90px;
						font-weight:100;
						padding:20px;
					}
					a {
						display:block;
						.readMore {
							border:1px solid $LinkColor;
							margin:40px 20px 20px 20px;
							padding:10px;
							color:#000000;
							text-transform: uppercase;
							display:inline-block;
							font-size:14px;
							@include transition($t1);
							&:hover {
								background-color:$LinkColor;
								color:#FFFFFF;
							}
						}
					}
					
				}
				section {
					margin-bottom:60px;
					p {
						text-align:justify;
					}
				}
				&:hover .imageInner {
					height:100% !important;
				}
			}
		}
	}
	
	.widgetNewsPrev, .widgetNewsNext {
		position: absolute;
		top:10px;
		display:inline-block;
		width:48px;
		height:48px;
		text-align:center;
		border:1px solid #FFFFFF;
		z-index:8;
		background-size: 50% 50%;
		background-position: center center;
		background-repeat: no-repeat;
		@include transition($t1);
		a {
			color:$LinkColor;
			width: 100%;
		    height: 100%;
		    display: block;
		}
		&:hover{
			background-color:$LinkColor;
			/*border:1px solid $LinkColor;*/
		}
	}
	.widgetNewsPrev {
		right:47px;
		background-image: url(../images/leftBracket.svg);
	}
	.widgetNewsNext {
		right:0px;
		background-image: url(../images/rightBracket.svg);
	}
}


.pageWrapper {
	display:flex;
	.columnLeft {width:300px; display:inline-block; padding:20px;
		.siteName {
			font-size: 18px;

		}
		.componentName {
			margin-left:20px;
			font-size:15px;
			padding:10px;
			&.active {
				background-color:green;
				color:#FFFFFF;
			}
		}
		
	}

	.columnRight{
		 display:inline-block;
		width: calc(100% - 300px);
		/*max-width: 900px;*/
		.HTMLdiv, .CSSdiv, .Responsivediv, .JSdiv {
			display:inline-block;
			margin:20px;
			border:2px solid #999999;
			padding:20px;
			height:300px;
			overflow-y:scroll;
			width:calc(50% - 40px);
			float:left;
		}

		.HTMLinner, .CSSinner, .Responsiveinner, .JSinner, .Notesinner {
			display: none;
		}
	}
	
}



.visible {
	display: block !important;
}

/*
|===============================================================
|	Homepage Upcoming Events Widget
|===============================================================
*/

.homepageEventsSliderWrapper {
	background-color: $mainColor;
	padding:30px;
	position:relative;
	#sliderUpcoming {
		width:100%;
		max-width:1280px;
		margin:0 auto;
		#eventsInner {
			z-index:1;
			section {
				margin-bottom:60px;
				.eventsContent {
					text-align: justify;
				}
			}
		}
	}
	#eventsNext, #eventsPrev {
		position: absolute;
		bottom:30px;
		display:inline-block;
		width:30px;
		height:30px;
		text-align:center;
		border: 2px solid $LinkColor;
		z-index:9999;
		a {
			color:$LinkColor;
		}
	}
	#eventsPrev {
		left:90px;
	}
	#eventsNext {
		left:140px;
	}
}

/*
|===============================================================
|	House Points Widget
|===============================================================
*/

.housepointsWrapper {
	width:100%;
	text-align:center;
	.housepointsInner {
		width:100%;
		max-width:1280px;
		margin:0 auto;
	   
    	text-align: center;
		.housepointsItem {
			width:200px;
			display:inline-block;
			margin:20px;
			background-position:center center;
			background-size:cover;
			background-repeat:no-repeat;
			padding:30px;
			.housepointsIcon {
				width:100%;
			}
		}
	}
}




/*
|===============================================================
|	Ticker
|===============================================================
*/

#tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	left:0px;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 46px;
}


.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight: lighter;
}

.tickertape a{
	color: #fff;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight: lighter;
}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 14px;
	z-index:999
}

.ticker {
	position: relative;
	max-height: 46px;
	width: 100%;
	background-color: #000000;
	z-index:2002;
	bottom:46px;
}

#LatestNews{
	position: absolute;
    left: 0px;
    width:180px;
    padding-left: 10px;
    height: 46px;
    color: #fff;
    z-index: 9999;
    line-height: 45px;
    font-size: 1em;
    text-transform: uppercase;
    padding-right: 20px;
}

/*
|===============================================================
|	Gallery
|===============================================================
*/

.galleryImage {
	display:inline-block;
	margin:20px;
	text-align:center; 
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}

#bx_pager {
	li {
		display: inline-block;
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid-item, .grid-sizer { 
	width: 33%; 
	float: left;
	img {
		width: 100%;
		height: auto;
	}
}
.grid-item--width2 { width: 400px; }

/*
|===============================================================
|	Page Header - Mock
|===============================================================
*/
header.pageNav {
	position:static;
	width:100%;
	.brand {
		top:0;
	}
	
}

#__toolbox-opener {
	display:none !important;
}

header {
	overflow:hidden;
	position:relative;
	.brand {
		position:fixed;
		top:-200px;
		z-index:99;
		width:100%;
		padding:20px 0 0 0;
		background-color:rgba(255,255,255,0.6);
		border-top:12px solid $LinkColor;
		@include animation(brandAnim 0.5s linear 1s forwards);
		@include transition($t1);
		.brandInner {
			width:100%;
			padding:0 20px;
			margin:0 auto;
			position:relative;
			.siteTitle {
				display: inline-block;
				margin: 10px 0 0 37px;
				text-align:left;
				float:left;
				@include transition($t1);
				.top{
					font-size:40px;
					text-transform: uppercase;
					@include transition($t1);
				}
				.bottom{
					font-size:23px;
					text-transform: uppercase;
					@include transition($t1);
					/*margin-top:10px;*/
				}
			}
			.homepageHeaderLogo {
				width:100px;
				max-width:600px;
				display:inline-block;
				@include transition($t1);
				float:left;
			}
			.homepageHeaderControls {
				width:50%;
				position:absolute;
				right:0;
				top:50%;
				@include transform(translateY(-50%));
				text-align:right;

			}
			nav.primary {
				text-align:right;
				margin-top:50px;
				display:inline-block;
				float:right;
				@include transition($t1);
				ul {
					li {
						display: inline-block;
						margin:0 20px;
						padding:20px 0;
						position: relative;
						a {
							display:block;
							margin-bottom:10px;
							&:hover{
								color:$LinkColor;
							}
						}
						.subMenuWrapper {
							position:absolute;
							width: 250px;
    						background-color: rgba(255,255,255,0.6);
    						left: 50%;
    						@include transform(translate(-50%, 100%));
    						bottom:0;
    						display:none;
    						 a {
    						 	text-align:left;
    						 }
						}
						.stack {
							position: relative;
							.children {
								margin-left:20px;
								display:none;
							}
							&:hover {
								/*.children {
									display:block;
								}*/
							}
							.toggleNav {

								position: absolute;
								right:0;
								top:0;
								cursor:pointer;
							}
						}
						&.current{
							border-top:1px solid $midGreen;
						}
						&:first-child {
							background-image: url(../images/homeIcon.svg);
							background-size:80% 80%;
							background-repeat:no-repeat;
							background-position: center top;
							width:30px;
							/*height:30px;*/
						}
						&:hover > .subMenuWrapper{
							display:block;
						}
					}
				}
			}
			.searchIcon {
				background-image:url(../images/searchIcon.svg);
				background-position: center center;
				background-size:50% 50%;
				background-repeat: no-repeat;
				background-color:$LinkColor;
				width:39px;
				height:39px;
				position:absolute;
				top:-20px;
				right:35px;
			}
		}
		&.compressed {
			background-color:#FFFFFF;
			.brandInner {
				.homepageHeaderLogo {
					width:70px;
				}
				.siteTitle {
					margin:5px 0 0 20px;
					.top{
						font-size:30px;
					}
					.bottom {
						font-size:18px;
					}
				}
				nav.primary {
					margin-top:10px;
				}
			}
		}
		
	}
	.pageHeader {
		background-color: #f4f4f4;
		width: 100%;
		height: 500px;
	}
	.flexslider {
	overflow:hidden;
		ul {
		overflow:hidden;
			li {
			overflow:hidden;
				position:relative;
				.slideBG {
					background-position:center center;
					background-size:cover;
					margin-top: 0 !important;
				}
			}
		}
	}
}

@keyframes brandAnim {
	to {
		top:0px;
	}
}

.HeadImage {
	width:100%;
	height:600px;
	background-position: center center;
	background-size: cover;
	
}

.RecordsListPage {
	.childList {
		
		li {
			margin:0 !important;
			width:250px;
		}
		
	}
}

	.childList {
		text-align:center;
		padding:60px 0;
		a {
			li {
				width:250px;
				height: 95px;
				/*background: #FFFFFF;*/
				padding:20px 60px 20px 10px;
				margin: 0px 10px;
				display:inline-block;
				text-transform:uppercase;
				position:relative;
				text-align:left;
				/*background-image:url(../images/rose.jpg);*/
				border:1px solid #000;
				background-repeat: no-repeat;
				background-position: right bottom;
				background-size: 60px;
				background: #de322e;
				background: -moz-linear-gradient(top,  #de322e 29%, #be0e03 99%);
				background: -webkit-linear-gradient(top,  #de322e 29%,#be0e03 99%);
				background: linear-gradient(to bottom,  #de322e 29%,#be0e03 99%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de322e', endColorstr='#be0e03',GradientType=0 );
				@include transition($t1);
				&:after {
					content:'';
					position:absolute;
					right:0;
					bottom:0;
					height: 100%;
					width:35%;
					background-image:url(../images/quicklinkRose.svg);
					background-repeat: no-repeat;
					background-position: right bottom;
					background-size:contain;
					opacity:0.3;
				}
				.titleText {
					display:inline-block;
					position: absolute;
					@include transform(translateY(-50%));
					top:50%;
					left:10px;
					color:#FFF;
					z-index:2;
				}
				
				
				&:hover{
					background:#000000;
				}
				&.black {
					width:300px;
					background:#000000;

					&:hover{
						background: #de322e;
						background: -moz-linear-gradient(top,  #de322e 29%, #be0e03 99%);
						background: -webkit-linear-gradient(top,  #de322e 29%,#be0e03 99%);
						background: linear-gradient(to bottom,  #de322e 29%,#be0e03 99%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de322e', endColorstr='#be0e03',GradientType=0 );
					}
				}
			}
		}
		&.top {
			display:none;
		}
	}

	.recordList {
		text-align:center;
		a {
			li {
				width:300px;
				height: 95px;
				/*background: #FFFFFF;*/
				padding:20px 60px 20px 10px;
				margin: 60px 10px;
				display:inline-block;
				text-transform:uppercase;
				position:relative;
				text-align:left;
				/*background-image:url(../images/rose.jpg);*/
				border:1px solid #000;
				background-repeat: no-repeat;
				background-position: right bottom;
				background-size: 60px;
				background:#000000;
				@include transition($t1);
				&:after {
					content:'';
					position:absolute;
					right:0;
					bottom:0;
					height: 100%;
					width:35%;
					background-image:url(../images/quicklinkRose.svg);
					background-repeat: no-repeat;
					background-position: right bottom;
					background-size:contain;
					opacity:0.3;
				}
				

					.titleText {
						display:inline-block;
						position: absolute;
						@include transform(translateY(-50%));
						top:50%;
						left:10px;
						color:#FFF;
					}
				
				
				&:hover{
					
					background: #de322e;
				background: -moz-linear-gradient(top,  #de322e 29%, #be0e03 99%);
				background: -webkit-linear-gradient(top,  #de322e 29%,#be0e03 99%);
				background: linear-gradient(to bottom,  #de322e 29%,#be0e03 99%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de322e', endColorstr='#be0e03',GradientType=0 );
				}
			}
		}
	}

.RecordsParentPage {
	.mainWrapper {
		.centerColumn {
			.centerLeft {
				.listWrapper {
					width:100%;
				}
			}
		}
	}
}

.ClubPage {
	.mainWrapper {
		.centerColumn {
			.centerLeft {
				/*display:flex;*/
			}
		}
	}
}

.sectionTitle {
	display: inline-block;
	width:100%;
	background-color: #000000;
	text-align: center;
	padding:20px 0;
	color:#FFFFFF;
	font-size:40px;
	text-transform: uppercase;
}

.mainWrapper {
	background-image: url(../images/welcomeBG2.svg);
	background-size: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
position: relative;
	.centerColumn {
		/*padding: 150px 10px;*/
		
		/*background-color:#FFFFFF;*/
		min-height:500px;
		width:100%;
		max-width:1462px;
		margin:60px auto 0 auto;
		position: relative;
		display: flex;
		.clubLink {
			width:250px;
			height:250px;
			display: inline-block;
			margin:0 20px 20px 0;
			border:1px solid $lightGrey;
			text-align:center;
			/*background-color:$midYellow;*/
			background-size:80%;
			background-position:center center;
			background-repeat:no-repeat;
			/*float:left;*/
			position: relative;
			a {
				display:block;
				height:100%;
				.clubTitle {
					color:$LinkColor;
					position: absolute;
					top:50%;
					text-align:center;
					@include transform(translateY(-50%));
					width:100%;
				}
			}
			
		}
		.clubLogoWrapper {
			width:250px;
			display: inline-block;
			height:100%;
			float:left;
			.clubLogo {
				width:250px;
				height:250px;
				border:1px solid $lightGrey;
				background-position: center center;
				background-size: 80% auto;
				background-repeat: no-repeat;
				background-color:$midYellow;
			}
		}
		.clubContact {
			margin-left:60px;
			width:calc(100% - 310px);
			display:inline-block;
			.contactTitle {
				color:$LinkColor;
				margin-right: 20px;
			}
			h3 {
				margin:30px 0;
			}
		}
		.centerLeft {
			/*width:calc(100% - 330px);*/
			display: inline-block;
			/*float: left;*/
			padding:0 30px 0px 10px;
			h1.parentTitle {
				display: block;
				text-transform: uppercase;
				color:#000000;
				padding-bottom:10px;
				margin-bottom: 60px;
			}
			h1 {
				display: inline-block;
				color:#000000;
				padding-bottom:10px;
				margin-bottom: 60px;
			}

			.recordsFrame {
				width:100%;
				height:600px;
				border:0;
			}

			.addToCalendar {

				img {
					margin-right:20px;
				}
			}
			.userform{
				.field {
					margin:30px 0;
					label {
						color:$LinkColor;
						/*font-family: $font-museo;*/
						font-weight: 100;
					}
					.text, .textarea {
						min-width:250px;
						width:75%;
						padding:10px;
						/*border: 1px solid $darkBlue;*/
						margin-top:10px;
					}
				}
				.action {
					min-width:250px;
					width:75%;
					padding:10px;
					border: 1px solid $LinkColor;
					border-radius:0;
					background-color:transparent;
					margin-bottom:30px;
					/*color:$darkBlue;*/
					@include transition($t1);
					&:hover {
						background-color:$LinkColor;
						color:#FFFFFF;
					}
				}
			}
			.listWrapper {
				width:45%;
				display:inline-block;
				li {
					width:250px;
					height: 95px;
					/*background: #FFFFFF;*/
					padding:20px 60px 20px 10px;
					/*margin: 60px 10px;*/
					display:inline-block;
					text-transform:uppercase;
					position:relative;
					text-align:left;
					/*background-image:url(../images/rose.jpg);*/
					border:1px solid #000;
					background-repeat: no-repeat;
					background-position: right bottom;
					background-size: 60px;
					background:#000000;
					color:#FFFFFF;
					@include transition($t1);
					&:after {
						content:'';
						position:absolute;
						right:0;
						bottom:0;
						height: 100%;
						width:35%;
						background-image:url(../images/quicklinkRose.svg);
						background-repeat: no-repeat;
						background-position: right bottom;
						background-size:contain;
						opacity:0.3;
					}
					

						.titleText {
							display:inline-block;
							position: absolute;
							@include transform(translateY(-50%));
							top:50%;
							left:10px;
							color:#FFF;
						}
					
					
					&:hover{
						background: #de322e;
						background: -moz-linear-gradient(top,  #de322e 29%, #be0e03 99%);
						background: -webkit-linear-gradient(top,  #de322e 29%,#be0e03 99%);
						background: linear-gradient(to bottom,  #de322e 29%,#be0e03 99%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de322e', endColorstr='#be0e03',GradientType=0 );
					}
				}
			}
			
			.galleryWrapper {
				display: inline-block;
				border:2px solid $lightGrey;
				padding:20px;
				position: relative;
				margin:0 20px 30px 0;
				overflow:hidden;
				.galleryDecoration {
					.galleryImageSm {
						width:250px;
						height:250px;
						border-radius:25px;
						background-size: cover;
						background-position:center center;
					}

				}
				.galleryTitle {
					text-align: center;
					margin:20px 0;
					border-top:1px solid $LinkColor;
					padding-top:20px;
					@include transition($t1);
				}
				&:after {
					content:'';
					width:100%;
					height:100%;
					background-color: $LinkColor;
					position: absolute;
					left:0;
					bottom:0;
					z-index:-1;
					@include transition($t1);
					opacity:0;
				}
				&:hover {
					.galleryTitle {
						border-top:1px solid #000000;
					}
					&:after {
						opacity:1;
					}
				}
				
			}
			.galleryImage {
				display: inline-block;
				border:2px solid $lightGrey;
				padding:20px;
				position: relative;
				margin:0 20px 30px 0;
				overflow:hidden;
				.thumb {
					width:200px;
					height:200px;
					border-radius:25px;
					background-size: cover;
					background-position:center center;
				}
				.galleryImageTitle {
					text-align: center;
					margin:20px 0 0 0;
					border-top:1px solid $LinkColor;
					padding-top:20px;
					@include transition($t1);
				}
				&:after {
					content:'';
					width:100%;
					height:100%;
					background-color: $LinkColor;
					position: absolute;
					left:0;
					bottom:0;
					z-index:-1;
					opacity:0;
					@include transition($t1);
				}
				&:hover {
					.galleryImageTitle {
						border-top:1px solid #000000;
					}
					&:after {
						opacity:1;
					}
				}
			}
			.Newsletter {
				border:2px solid $lightGrey;
				padding: 20px;
				margin:0 0 20px 0;
				position: relative;
				overflow:hidden;
				&:after {
					content:'';
					width:100%;
					height:100%;
					opacity:0;
					background-color: $LinkColor;
					position: absolute;
					left:0;
					bottom:0;
					z-index:-1;
					@include transition($t1);
				}
				&:hover {
					border:2px solid #000000;
					&:after {
						opacity:1;
					}
				}
			}
			#SearchResults li {
				margin-bottom:30px;
			}
			.staffProfile {
				.staffProfilePic {
					width:200px;
					height:200px;
					background-size: cover;
					background-position:center center;
					border-radius:20px;
				}
			}
			.Upload {
				border:2px solid $lightGrey;
				padding: 20px;
				margin:0 0 20px 0;
				position: relative;
				overflow:hidden;
				&:after {
					content:'';
					width:100%;
					height:100%;
					opacity:0;
					background-color: $LinkColor;
					position: absolute;
					left:0;
					bottom:0;
					z-index:-1;
					@include transition($t1);
				}
				&:hover {
					border:2px solid #000000;
					&:after {
						opacity:1;
					}
				}
			}
			.newsTeaserWrapper {
				/*display:flex;*/
				.news-teaser {
					margin:0 30px 30px 0;
					width:calc(33% - 30px);
					height:280px;
					display:inline-block;
					float:left;
					border:1px solid $LinkColor;
					position: relative;
					.dateBar {
						padding:5px;
						background-color:$LinkColor;
						color:#FFFFFF;
						font-size:12px;
						text-transform: uppercase;
					}
					.inner {
						padding:30px;
						h3 {
							color:#000000;
						}
					}
					a {
						

					}
					.newsHolderText {
						.content {
							width: calc(100% - 170px);
		    				display: inline-block;
						}
						.button-hover {
							display:inline-block;
							margin-top:30px;
							color:$LinkColor;
						}
					}
					.readMore {
						color:#000000;
						border:1px solid $lightGrey;
						padding:10px 30px;
						text-align:left;
						margin-top:30px;
						position: absolute;
						bottom:30px;
						left:30px;
						&:hover {
							background-color:$LinkColor;
							border:1px solid $LinkColor;
							color:#FFFFFF;
						}
					}
				}
			}
			
			.contentWrapper {
				margin-bottom:60px;
			}
		}
		.centerRight {
			/*width:25%;*/
			display: inline-block;
			padding: 0 10px;
			/*margin-top:60px;*/
			min-width:330px;
			/*position: absolute;*/
			.title {
				text-transform: uppercase;
				border-bottom:1px solid $midYellow;
				padding-bottom:10px;
				margin-bottom:20px;
			}
			a {
				text-transform: uppercase;
				.uploadItem {
					margin-bottom: 10px;
					@include transition($t1);
					&:hover {
						color:$LinkColor;
					}
				}
			}
		}
	}
}


/*
|===============================================================
|	Home Page Footer - Mock
|===============================================================
*/

.homepageFooter {
	width: 100%;
	background-color: #FFFFFF;
	border-top:1px solid $lightGrey;
	.footerLeft {
		display:inline-block;
		width:20%;
		text-align:center;
		position: absolute;
    	left: 0;
		.asaLogo{
			margin:20px;
			display:inline-block;
		}
	}
	.footerCenter {
		display:inline-block;
		position:absolute;
		left:50%;
		vertical-align: top;
		padding:35px 0 0 0;
		width:60%;
		text-align:center;
		@include transform(translateX(-50%));
		.schoolLogo {
			width:80px;
			position: absolute;
			left:50%;
			@include transform(translateX(-50%));
			top:-65px;
		}
		h4 {
			font-weight:100;
			color:#000000;
			text-transform:uppercase;
		}
	}
}

/*.NewsHolder {
	.centerColumn {
		.centerLeft {
			padding:0;
			width:100%;
		}
	}
}*/

.ClubHolder {
	.centerColumn {
		.centerLeft {
			text-align:center;
		}
	}
}


/*
|===============================================================
|	Page Footer - Mock
|===============================================================
*/

pageFooter {
	width: 100%;
	min-height: 400px;
	background-color: $mainColor2;
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

/*
|===============================================================
|	Contact Form
|===============================================================
*/

.contactForm {
	padding: 50px 0;
	float: left;
	.col-6 {
		float: left;
	}
}

/*
|===============================================================
|	Home Page 2 Column Section
|===============================================================
*/


.homepageTwoColumnWrapper {
	position:relative;
	float:left;
	display:flex;
}



/*
|===============================================================
|	Twitter
|===============================================================
*/

.homepageTwitter {
	position:relative;
	padding:30px;
	background: $textLight;
	.twitterPrev, .twitterNext {
		width:20px;
		height:20px;
		position:absolute;
		top:50%;
		@include transform(translateY(-50%));
		color:#FF00FF;
		cursor:pointer;
	}
	.twitterPrev {
		content:'⇦';
		left:-40px;
	}
	.twitterNext {
		content:'⇨';
		right:-40px;
	}
	section {
		max-width:1280px;
		width:100%;
		margin:0 auto;
		padding:20px;
	}
	.homepageTwitterThumb {
		width:120px;
	}
}

#tweets {
	position: relative;
	padding: 0;
	
	h1 {
		font-weight: 300;
		text-transform: uppercase;
		text-align: center;
	}
	.tweets {
		text-align: center;
		.tweetFrame {
			display: inline-block;
			width: 100%;
			max-width: 550px;
			.tweetItem {
				width: 100%;
				color: $mainColor2;
				padding-bottom: 20px;
				p {
					font-weight: 300;
					color: black;
					line-height: 30px;
					font-size: 25px;
					height: 300px;
				}
			}

			.frame {
				border-top: 2px solid black;
			}
			.tweetHandle {
				a {
					font-weight: 300;
					color: red;
				}
			}
			.tweetMeta {
				width: 35%;
				padding: 10px 0;
				text-align: left;
				float: left;
				.meta1, .metaRetweet, .metaLike {
					display: inline-block;
					margin-right: 15px;
					svg {
						path {
							fill: black;
						}
					}
					svg:hover {
						path {
							fill: red;
						}
					}
				}
			}
			.tweetData {
				float: left;
				width: 65%;
				text-align: right;
				padding-top: 15px;
				text-align: right;
				p {
					color: $mainColor;
					display: inline-block;
					margin-left: 10px;
					display: inline-block;
					height: auto;
					font-size: 20px;
					span {
						color: blac;
					}	
				}
			}
		}
	}
	.controlNav {
		position: relative;
		.prev, .next {
			position: relative;
			border: 1px solid $mainColor;
			display: inline-block;
			padding: 10px;
			margin: 0;
			height: auto;
			line-height: 0px;
			cursor: pointer;
		}
		.next {
			margin-left: 20px;
		}
		.prev:hover, .next:hover {
			background: red;
			svg {
				polygon {
					fill: white;
				}
			}
		}
	}
	.bgs {
		position: absolute;
		display: none;
		width: 100%;
		height: 100%;
		.yellow {
			position: relative;
			background-color: white;
			width: 50%;
			height: 100%;
		}
	}
}

/*
|===============================================================
|	Google Map
|===============================================================
*/
#gmap_canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

}
.mapBox {
	position: relative;
	width: 100%;
	padding: 50px;
	height:350px;
	.address {
		position: relative;
		background: rgba(255,255,255,0.8);
		padding: 80px;
		display: inline-block;
		z-index: 9999;
		ul {
			padding-left: 0;
			margin: 0;
			padding-right: 20px;
			li {
				list-style: none;
				margin-bottom: 5px;
				h3, i, p span {
					color: $mainColor2;
				}
				h3 {
					margin-top: 0px;
					font-weight: 100;
					span {
						font-weight: 700;
					}
				}
				p {
					color: $mainColor;
					font-size: 22px;
					margin-bottom: 0;
					font-weight: 100;
				}
				i {
					display: inline-block;
				}
			}
		}
		li.contact {
			margin-top: 40px;
		}
	}
}

/*
|===============================================================
|	Notices Modal
|===============================================================
*/
#modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top:0;
	left:0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color:rgba(0,0,0,0.5);
	z-index: 9999;
	.modalWrapper {
		padding: 40px;
		background: rgba(255,255,255,0.8);
		text-align:center;
	}
	h1 {
		text-align:center;
	}
	.modalBox {
		
		width: 500px;
		/*height: 300px;*/
		a {
			color:$LinkColor;
		}
		ul {
			li {
				height: 150px;
			}
		}
		h1 {
			color: $mainColor2;
		}
		h1, p {
			color: black;
			text-align: center;
		}
		.bx-pager {
			width: 100%;
			text-align: center;
			.bx-pager-item {
				position: relative;
				display: inline-block;
				margin: 0 5px;
				a.bx-pager-link {
					position: relative;
					display:block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					text-indent: -9999px;
					background: black;
					opacity: 0.5;
				}
				a.bx-pager-link.active {
					opacity: 1;
				}
			}
		}
	}
	.close {
		padding:5px 30px;
		border:2px solid $LinkColor;
		margin:0 auto;
		display:inline-block;
		background-color:transparent;
		color:$LinkColor;
		cursor:pointer;
		@include transition($t1)
		&:hover {
			background-color:$LinkColor;
			color:#FFFFFF;
		}
	}
}

.priorityAlert {
	position:absolute;
	-webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    left:50%;
    top:50%;
    width:30%;
    min-width:240px;
    padding:40px;
    text-align: center;
    font-size:30px;
    background-color:$LinkColor;
    line-height:40px;
    color:#FFFFFF;
    z-index:1;
    .priorityAlert a {
		color:#FFFFFF;
	}

	.priorityAlertClose {
		display:block;
		width:100%;
		color:#FFFFFF;
		font-size:16px;
		line-height:40px;
		margin-top:30px;
		cursor:pointer;
	}
}

.priorityAlert a {
	color:#FFFFFF;
}

.priorityAlertClose {
	display:block;
	width:100%;
	color:#FFFFFF;
	font-size:16px;
	line-height:40px;
	margin-top:30px;
	cursor:pointer;
}

/*
|===============================================================
|	Quick Links
|===============================================================
*/

.HomePage {
	#quickLinks {
		position: absolute;
		bottom:100px;
		right: 0;
		padding:10px;
		li {
			bottom:-240px;
			@include transition($t1);
			&:nth-child(4n+1){@include animation(quicklinkAnim 1s ease 1.2s forwards);}
			&:nth-child(4n+2){@include animation(quicklinkAnim 1s ease 1.4s forwards);}
			&:nth-child(4n+3){@include animation(quicklinkAnim 1s ease 1.6s forwards);}
			&:nth-child(4n+4){@include animation(quicklinkAnim 1s ease 1.8s forwards);}
			&:hover {
				background:#000000;
			}
		}
	}
}

#quickLinks {
	
	display: block;
	
	width: 100%;
	padding:90px 0;
	z-index: 20;
	text-align:center;
	li {
		width:200px;
		height: 95px;
		/*background: #FFFFFF;*/
		padding:20px 60px 20px 10px;
		margin: 10px;
		display:inline-block;
		text-transform:uppercase;
		position:relative;
		text-align:left;
		/*background-image:url(../images/rose.jpg);*/
		border:1px solid #000;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 60px;
		background: #de322e;
		background: -moz-linear-gradient(top,  #de322e 29%, #be0e03 99%);
		background: -webkit-linear-gradient(top,  #de322e 29%,#be0e03 99%);
		background: linear-gradient(to bottom,  #de322e 29%,#be0e03 99%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de322e', endColorstr='#be0e03',GradientType=0 );
		&:after {
			content:'';
			position:absolute;
			right:0;
			bottom:0;
			height: 100%;
			width:35%;
			background-image:url(../images/quicklinkRose.svg);
			background-repeat: no-repeat;
			background-position: right bottom;
			background-size:contain;
			opacity:0.3;
		}
		
		.titleText {
			display:inline-block;
			position: absolute;
			@include transform(translateY(-50%));
			top:50%;
			left:10px;
			color:#FFF;
		}
		.homepageQLIcon {
			width:20px;
			height:20px;
			background-size:contain;
			background-position:center;
			display:inline-block;
		}
		
		i {
			color: white;
			font-size: 30px;
		}
		
	}
}

@keyframes quicklinkAnim {
	to {
		bottom:0px;
	}
}

/*
|===============================================================
|	Useful Links
|===============================================================
*/

#usefulLinks {
	max-width:1280px;
	width: 100%;
	text-align:center;
	li {
		width: 300px;
		margin: 10px;
		display: inline-block;
		align-items: center;
		text-align:center;
		background: $mainColor;
		padding: 20px;
		color: white;
		font-weight: 100;
		.titleText {
			display:block;
		}
		.homepageQLIcon {
			width:100px;
			height:100px;
			background-size:contain;
			background-position:center;
			display:inline-block;
		}
		
		i {
			color: white;
			font-size: 30px;
		}
	}
}


/*
|===============================================================
|	Content
|===============================================================
*/


.contentMiddleColumn {
	padding: 50px 0;

	.sideBarFrame {
		border-right: 2px solid $mainColor;
		min-height: 200px;
	}

	.contentFrame {
		padding-left: 20px;
	}

}

/*
|===============================================================
|	Vacancy
|===============================================================
*/

.vacancyHolder {
	.vacancy__item {
		border-bottom: 1px solid $mainColor;
		h4 {
			padding-top: 20px;
			font-weight: 300;
			span {
				font-weight: bold;
			}
		}
	}
}

/*
|===============================================================
|	Welcome Message
|===============================================================
*/
#welcomeMessage {
	padding: 150px 10px;
	background-image: url(../images/welcomeBG2.svg);
	background-size: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-color:#FFFFFF;
	min-height:500px;

	.inner {
		width:100%;
		max-width:900px;
		margin:0 auto;
		.welcomeTitle {
			width:33%;
			display:inline-block;
			vertical-align: top;
			h1 {
				font-weight:100;
			}
		}
		.welcomeContent {
			width:66%;
			display:inline-block;
			padding:0 0 0 60px;
		}
	}
}

/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToTop {
	position: absolute;
	bottom: -128px;
	right: 30px;
	background: $LinkColor;
	width:60px;
	height:70px;
	background-image: url(../images/upBracket.svg);
	background-repeat: no-repeat;
	background-position: center 15px;
	background-size:60%;
	z-index: 9999;
	cursor: pointer;
	
}
/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToBottom {
	position: absolute;
	bottom: 50px;
	left: 50%;
	width: 50px;
	height: 50px;
	/*margin-left: -30px;*/
	padding: 10px;
	border-radius: 50%;
	z-index: 9999;
	cursor: pointer;
	background-image: url(../images/downBracket.svg);
	background-repeat: no-repeat;
	background-position: center 15px;
	background-size:80%;
	@include transform(translateX(-50%));
	i {
		color: white;
	}
}

/*
|===============================================================
|	Calendar
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	padding-bottom: 50px;
	thead {
		background:	#DCDCDC;
		tr {
			th {
				border: none;
				padding: 20px;
				.show-month, .next, .prev {
					color: #FFFFFF;
					font-weight: bold;
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: $mainColor;
		}
	}
	tbody{
		.calendar-day {
			border: none;
		}
		td {
			padding: 20px;
		}
	} 
	.calendar-day:hover, .selected {
		/*background-color: $mainColor2 !important;*/
		color: black;
		font-weight: 500;
	}
}


/*
|===============================================================
|	Calendar Filter Isotope
|===============================================================
*/

.button {
  display: inline-block;
  padding: 0.5em 1.0em;
  background: #EEE;
  border: none;
  border-radius: 7px;
  background-image: linear-gradient( to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.2) );
  color: #222;
  font-family: sans-serif;
  font-size: 16px;
  text-shadow: 0 1px white;
  cursor: pointer;
}

.button:hover {
  background-color: #8CF;
  text-shadow: 0 1px hsla(0, 0%, 100%, 0.5);
  color: #222;
}

.button:active,
.button.is-checked {
  background-color: #28F;
}

.button.is-checked {
  color: white;
  text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
}

.button:active {
  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}

/* ---- button-group ---- */

.button-group {
  margin-bottom: 20px;
}

.button-group:after {
  content: '';
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  margin-left: 0;
  margin-right: 1px;
}

.button-group .button:first-child { border-radius: 0.5em 0 0 0.5em; }
.button-group .button:last-child { border-radius: 0 0.5em 0.5em 0; }

/* ---- isotope ---- */

.grid-item {
	width: 25%;
}
.event-inner{
	margin: 10px;
	padding: 10px;
	background: #ccc;
}


/* end isotope */


.events_page {
	h2.title {
		color: $mainColor2;
		font-weight: 300;
		text-transform: uppercase;
	}
	a {
		color: grey;
	}
	a.url {
		color: $mainColor2;
		font-weight: 300;
	}
}

