@media all and (max-width: 1280px)  {
	.ClubPage {
		.centerColumn {
			.centerLeft {
				width:100%;
			}
		}
	}
}

@media all and (max-width:1024px) {
	.brand {
		position:relative;
		@include animation(none !important);
		top:0 !important;
		.brandInner {
			text-align: center;
			padding-bottom:20px !important;
			.homepageHeaderLogo {
				/*margin-top:40px;*/
				float:none !important;
				margin: 0 auto;
				display: block;
			}
			.siteTitle {
				float:none !important;
				/*text-align:center !important;*/
					margin-bottom:20px !important;
					margin-left:0 !important;
					display:inline-block !important;
					/*margin-top:-10px !important;*/
					vertical-align:bottom;
			}
			
			nav.primary {
				display: none !important;
			}
		}
	}

	#MenuIcon {
		display:inline-block !important;
	}
}

/*iPad*/
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}

@media all and (max-width: 991px)  {
	.mainWrapper .centerColumn .centerLeft .listWrapper {
	width:100%
	}
}


@media all and (max-width: 768px)  {
	.clearSmall{
		clear: both;
		width: 100% !important;
	}

	.tacMid{
		text-align: center !important;
	}
	header {
		.scrollToBottom {
			display:none;
		}
		.brand {
			position:relative;
			@include animation(none !important);
			top:0 !important;
			.brandInner {
				text-align: center;
				.homepageHeaderLogo {
					/*margin-top:40px;*/
					float:none !important;
					margin: 0 auto;
    				display: block;
				}
				.siteTitle {
					float:none !important;
					text-align:center !important;
					margin-bottom:20px !important;
				}
				
				nav.primary {
					display: none;
				}
			}
		}
		.ticker {
			bottom:0px;
		}
		#quickLinks {
			padding:0;
			margin-top:40px;
			ul {
				margin:0;
				li{
					@include animation(none !important);
					bottom:0 !important;
					width:100%;
					padding:0 !important;
					margin:0;
				}
			}
		}
	}

	#MenuIcon {
		display:inline-block !important;
	}

	#quickLinks  {
		position: relative !important;
		bottom:0 !important;
	}
	#welcomeMessage {
		padding:60px 10px;
		.inner {
			.welcomeTitle {
				width:100%;
				display:inline-block;
				h1 {
					text-align:center;
				}
			}
			.welcomeContent {
				width:100%;
				display:inline-block;
			}
		}
	}
	.mainWrapper {
		.centerColumn {
			.centerRight {
				width:100%;
				display:block;
				margin-top:30px;
				position: relative;
			}
			.centerLeft {
				width:100%;
				padding:0 10px 0 10px;
				.listWrapper {
					text-align: center;
					margin-bottom:30px;
				}
				.clubLogoWrapper {
					width:100%;
					margin-bottom:30px;
					.clubLogo {
						margin:0 auto;
					}
				}
				.clubContact {
					margin-left:0;
					width:100%;
				}
			}
		}
	}
	.homepageFooter {
		.footerCenter {
			position: relative;
			width:100%;
		}
		.footerLeft {
			position: relative;
			width:100%;
		}
	}
	.scrollToTop {
		bottom:0;
	}
	.mainWrapper .centerColumn .centerLeft .userform .field .text, .mainWrapper .centerColumn .centerLeft .userform .field .textarea {
		width:100%;
	}
	.mainWrapper .centerColumn .centerLeft .userform .action {
		width:100%;
	}

	.childList.top {
		display:none;
	}
	.childList.bottom {
		display:block;
	}
}

@media all and (max-width: 580px)  {
	header{
		.brand {
			.brandInner {
				.siteTitle {
					margin-left:0;
				}
			}
		}
	}
}

@media all and (max-width: 650px)  {
	.welcomeContent {
		padding:0 0 0 0 !important;
	}
	.homepageNewsSliderWrapper{
		.topControls {
			h1 {
				font-size:30px;
			}
			.widgetNewsPrev, .widgetNewsNext {
				top:68px;
			}
			.viewMoreBtn {
				top:68px;
				    padding: 10px 27px;
			}
		}
		.outer {
		    margin: 60px auto 30px auto;
		}
	} 
}

@media all and (max-width: 480px)  {

	.tacSmall{
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
	.siteTitle {
		text-align:center !important;
		.top {
			font-size:28px !important;
		}
	}
}

@media all and (max-height: 768px)  {
	.HeadImage {
		height:300px !important;
	}
}
